import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a24412b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "page_footer" }
const _hoisted_2 = { id: "footer_text_content" }
const _hoisted_3 = { id: "text_content" }
const _hoisted_4 = { id: "text_content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString($data.copyright), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString($data.address), 1)
    ])
  ]))
}