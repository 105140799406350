<template>
  <div id="m_content">
    <router-view />
    <div id="bottom">
      <hr id="tabbar_line" />
      <TabBar />
      <page-footer />
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import TabBar from "@/components/Mobile/TabBar.vue";
import PageFooter from "@/components/Mobile/PageFooter.vue";
import Wxconfig from "@/config/Mobile/WXshare";

@Options({
  components: {
    TabBar,
    PageFooter,
  },
  beforeCreate() {
    window.document.title = this.$route.name;
  },
  updated() {
    window.document.title = this.$route.name;
    const link = this.getUrl();
    Wxconfig.wxShowMenu({
      titles: '万非',
      titles_circle: '万非',
      descs: '万非，就是芦荟！ 万非，又不只是芦荟！',
      link: link,
      imgUrl: 'https://wf.hzno.cn/img/logo.2b651380.png',
      ajaxUrl: 'https://wfx.2268mall.com/weixin/sign_package_web.json?url=' + encodeURIComponent(link),
    });
  },
  created() {
    const link = this.getUrl();
    // console.log(link);
    Wxconfig.wxShowMenu({
      titles: '万非',
      titles_circle: '万非',
      descs: '万非，就是芦荟！ 万非，又不只是芦荟！',
      link: link,
      imgUrl: 'https://wf.hzno.cn/img/logo.2b651380.png',
      ajaxUrl: 'https://wfx.2268mall.com/weixin/sign_package_web.json?url=' + encodeURIComponent(link),
    });
  },
  methods: {
    getUrl() {
      return window.location.href;
    },
  },
})
export default class AppMobile extends Vue {}
</script>

<style lang="scss">
#m_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

#bottom {
  position: fixed;
  flex-direction: column;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dcdcdc;
  z-index: 100001;
  font-size: calc(30 / 1080 * 100vw);
  min-height: calc(120 / 1080 * 100vw);
}

#tabber_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #dcdcdc;
}

#tabbar_line {
  width: 100%;
  padding: 0px;
  margin: 0px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #99999940;
}
</style>
