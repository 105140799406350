<template>
  <div id="tabbar">
    <router-link
      v-for="(item, index) in items"
      :to="item.path"
      :key="index"
      v-on:click="onClick(index)"
    >
      <div id="tab">
        <div
          :id="this.$route.path.includes(item.path) ? 'main_title' : 'title'"
        >
          {{ item.title }}
        </div>
        <hr id="line" v-show="this.$route.path.includes(item.path)" />
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "TabBar",
  props: {
    list: Array,
  },
  data() {
    return {
      selectedIndex: 0,
      items: [
        {
          path: "/main",
          title: "首页",
          name: "首页",
        },
        {
          path: "/brand",
          title: "品牌",
          name: "品牌故事",
        },
        {
          path: "/base",
          title: "基地",
          name: "万亩基地",
        },
        {
          path: "/aloe",
          title: "商城",
          name: "芦荟护肤",
        },
        {
          path: "/prodev",
          title: "研发",
          name: "生产研发",
        },
        {
          path: "/joinus",
          title: "加入",
          name: "加入万非",
        },
      ],
    };
  },
  methods: {
    onClick(index) {
      this.selectedIndex = index;
      window.document.title = this.items[index].name;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#tabbar {
  padding: 0pt calc(40 / 1080 * 100vw) 0vw calc(40 / 1080 * 100vw);
  height: calc(120 / 1080 * 100vw);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  background-color: #ffffff;
}

#tab {
  height: calc(120 / 1080 * 100vw);
  display: flex;
  flex-direction: column;
}

#title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(130 / 1080 * 100vw);
  height: calc(72 / 1080 * 100vw);
  color: #333333;
  font-size: calc(34 / 1080 * 100vw);
  font-weight: bold;
  margin-top: calc(24 / 1080 * 100vw);
}

#main_title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(130 / 1080 * 100vw);
  height: calc(72 / 1080 * 100vw);
  color: #ffffff;
  background-color: #337a00;
  border-radius: calc(8 / 1080 * 100vw);
  font-size: calc(34 / 1080 * 100vw);
  font-weight: bold;
  margin-top: calc(24 / 1080 * 100vw);
}

#line {
  width: 100%;
  height: calc(10 / 1080 * 100vw);
  background-color: #337a00;
  margin: calc(14 / 1080 * 100vw) 0vw 0vw 0vw;
  padding: 0vw;
  border: none;
}

a {
  text-decoration: none;
}
</style>